import React from "react";
import Logo from "./images/deloitte.png";
import startButton from "./images/startbtn.png";
import audioButton from "./images/audiobtn.png";
import roomCircle from "./images/circle.png";
import "./App.css";

const App = () => {
  let roomArray = ["finance", "tax", "work", "legal"];

  const url_string = window.location.href;
  const url = new URL(url_string);
  var room = url.searchParams.get("room");
  let sub = "tnl";

  switch (room) {
    case "tax":
      sub = "tnl";
      roomArray = ["finance", "tax", "work", "legal"];
      break;
    case "work":
      sub = "fow";
      roomArray = ["tax", "work", "legal", "finance"];
      break;
    case "finance":
      sub = "fa";
      roomArray = ["legal", "finance", "tax", "work"];
      break;
    case "legal":
      sub = "legal";
      roomArray = ["work", "legal", "finance", "tax"];
      break;
    default:
      sub = "tnl";
      roomArray = ["finance", "tax", "work", "legal"];
  }

  const handleClick = () => {
    let accesskey = document.getElementById("accesskey").value.toUpperCase();
    decodeKey(accesskey);
  };

  const decodeKey = (key) => {
    if (key === "D9453") {
      // goto correct subdomain
      window.location.href = "https://" + sub + ".thedigitalescape.nl";
    } else return false;
  };

  const playTestSound = () => {
    let audio = new Audio("/audio/audiotestclown.mp3");
    audio.play();
  };

  const switchRoom = (room) => {
    window.location.href = "/?room=" + room;
  };

  return (
    <>
      <div className="App">
        <div className="container-fullwidth">
          <div className="section-access">
            <div className="deloitteLogo">
              <img src={Logo} alt="logo" />
            </div>

            <h2 className="title-access">Step into the future of</h2>
            <div className="roompicker">
              <div className="room">
                <div className="room-circle">
                  <img
                    className="circleImgGrey"
                    src={roomCircle}
                    alt="Room"
                    onClick={() => switchRoom(roomArray[0])}
                  />
                </div>
                <div className="room-title-grey">
                  {roomArray[0].toUpperCase()}
                </div>
              </div>
              <div className="room">
                <div className="room-circle">
                  <img
                    className="circleImg"
                    src={roomCircle}
                    alt="Room"
                    onClick={() => switchRoom(roomArray[1])}
                  />
                </div>
                <div className="room-title">{roomArray[1].toUpperCase()}</div>
              </div>
              <div className="room">
                <div className="room-circle">
                  <img
                    className="circleImgGrey"
                    src={roomCircle}
                    alt="Room"
                    onClick={() => switchRoom(roomArray[2])}
                  />
                </div>
                <div className="room-title-grey">
                  {roomArray[2].toUpperCase()}
                </div>
              </div>
              <div className="room">
                <div className="room-circle">
                  <img
                    className="circleImgGrey"
                    src={roomCircle}
                    alt="Room"
                    onClick={() => switchRoom(roomArray[3])}
                  />
                </div>
                <div className="room-title-grey">
                  {roomArray[3].toUpperCase()}
                </div>
              </div>
            </div>

            <div className="form-access">
              <div className="form-access-wrapper">
                <input
                  type="text"
                  className="input-access"
                  maxLength="24"
                  placeholder="Deloitte Access Key"
                  id="accesskey"
                />
              </div>
              <img
                className="audioButton"
                src={audioButton}
                alt="Start"
                onClick={() => playTestSound()}
              />
              <img
                className="startButton"
                src={startButton}
                alt="Start"
                onClick={() => {
                  handleClick();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
